import styled from "styled-components";
import BoltImg from "./Bolt";
import FlickerAnimation from "./Flicker";

const HeroWrapper = styled.div`
  grid-column: span 12;
  height: 100vh;
  min-height: 400px;
  background-color: black;
  position: relative;
`;

const Logo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  filter: drop-shadow(0px 0px 1px white);

  svg {
    max-height: 100vh;
    filter: drop-shadow(0 0 0.5rem rgba(250, 0, 0, 0.76));
    position: absolute;
    width: 90%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (min-width: 760px) {
      width: 60%;
    }

    @media (min-width: 1000px) {
      width: 40%;
      top: 45%;
    }
  }
`;

const NonnaLogo = styled.g`
  .flicker1 {
    animation: ${FlickerAnimation} 5s linear 3;
  }
  .flicker2 {
    animation: ${FlickerAnimation} 6s linear 1s 3;
  }
`;

const Hero = () => {
  return (
    <HeroWrapper>
      <Logo>
        <svg viewBox="0 0 378.15 352.59" xmlns="http://www.w3.org/2000/svg">
          <NonnaLogo fill="#f29699" transform="translate(-118.9 -224.31)">
            <path
              className="flicker1"
              d="m484 381.6h12.9c.1 3.2 0 6.2 0 9.3h-45.3c-.3-3-.6-6-.9-9.2 2.2-.2 4.4-.1 6.6-.1h13c-6.3-69-65.5-139.4-152.9-143.8-94.4-4.8-162.9 67.3-170.6 143.8h19.6c-.3 3.2-.6 6.3-.9 9.4h-46.5c0-.6-.1-1.2-.1-1.7v-7.5h14.2c6.9-52.5 31.1-94.6 74.1-125.3 33.7-24 71.6-34.4 112.8-31.8 88.3 5.5 155.5 74.8 164 156.9z"
            />
            <path
              className="flicker1"
              d="m133.1 419.3h-14.1v-9.2h46.5c.3 3 .6 6 .9 9.2-2.3.1-4.4 0-6.6.1h-13c6.4 69.4 65.9 139.3 152.8 143.9 94 5 163.2-67.1 170.8-143.7-1.6-.5-3.2-.2-4.8-.2s-3.3 0-4.9 0h-9.9c.3-3.2.6-6.2.9-9.1 1.4-.4 41.2-.6 45-.2a35.4 35.4 0 0 1 .1 9.3h-12.7c-6.3 49.8-28.7 90.4-68.3 121.1-31.5 24.4-67.4 36.5-107.3 36.4-44.9-.1-84.3-15.2-117.7-45.1-33.3-30.1-52.2-67.8-57.7-112.5z"
            />
            <path
              className="flicker1"
              d="m309.5 520.7c-39.3-1-71.3-15.6-95.8-45.3a115 115 0 0 1 -25.7-59.8c-5.7-44.7 14.2-87.7 50.5-113a118.48 118.48 0 0 1 54.8-21c36.7-4.2 69.3 5.7 97 30.3 21.3 18.9 34.2 42.6 38.5 70.8a118.76 118.76 0 0 1 -50.5 116.1 120.17 120.17 0 0 1 -54.4 20.6c-5.1.7-10.2.9-14.4 1.3zm-.8-6.6a113.57 113.57 0 0 0 113.5-113.3c.2-61.7-49.8-113.8-113.7-113.7-63.6.1-113.4 52-113.3 113.5a113.54 113.54 0 0 0 113.5 113.5z"
            />
            <path
              className="flicker1"
              d="m284.7 273.1a12.08 12.08 0 0 1 -12.2-12 12.36 12.36 0 0 1 12.4-12.2 12.08 12.08 0 0 1 12.2 12 12.29 12.29 0 0 1 -12.4 12.2zm.1-3.8a8.3 8.3 0 1 0 -8.3-8.4 8.18 8.18 0 0 0 8.3 8.4z"
            />
            <path
              className="flicker1"
              d="m256.1 543.6a12.38 12.38 0 0 1 -12.4-12.3 11.87 11.87 0 0 1 12.2-11.9 12.38 12.38 0 0 1 12.4 12.3 11.93 11.93 0 0 1 -12.2 11.9zm0-3.8a8.32 8.32 0 0 0 8.3-8.2 8.43 8.43 0 0 0 -8.6-8.4 8.3 8.3 0 1 0 .3 16.6z"
            />
            <path
              className="flicker1"
              d="m401.3 283.5a11.82 11.82 0 0 1 -11.9 12 12.4 12.4 0 0 1 -12.6-12.3 11.89 11.89 0 0 1 11.9-12 12.49 12.49 0 0 1 12.6 12.3zm-12.4-8.5a8.4 8.4 0 1 0 8.6 8.2 8.52 8.52 0 0 0 -8.6-8.2z"
            />
            <path
              className="flicker1"
              d="m364.9 534.6a12.53 12.53 0 0 1 -12.5 12.3 12.14 12.14 0 0 1 -12.2-12 12.38 12.38 0 0 1 12.5-12.2 11.87 11.87 0 0 1 12.2 11.9zm-12.4 8.5a8.4 8.4 0 1 0 -8.3-8.5 8.52 8.52 0 0 0 8.3 8.5z"
            />
            <path
              className="flicker1, flicker2"
              d="m363.6 257.1c.4 0 .5.1.5.1 2 .4 2.9 1.5 3 3.8.2 4 .7 8 1.1 12a.87.87 0 0 0 .4.7c2-4 3.6-8.2 5.5-12.4 1.1.5 2.1.9 3.3 1.4-3.1 7.4-6.2 14.7-9.3 22.2a26 26 0 0 0 -2.5-1c-.7-5.4-1.3-10.7-2-16.1-.1 0-.3-.1-.4-.1-1.8 4.2-3.6 8.5-5.5 12.9-1.1-.5-2.1-.9-3.4-1.4 3.1-7.5 6.2-14.8 9.3-22.1z"
            />
            <path
              className="flicker1"
              d="m394.3 497c4.9 6.4 9.7 12.8 14.7 19.3l-2.9 2.3c-2.5-3.3-4.9-6.4-7.3-9.5-.1 0-.2.1-.4.1.1 2.9.4 5.8.5 9-3-1-5.8-2-8.6-3-.1.1-.1.2-.2.3 2.4 3.1 4.7 6.2 7.2 9.5-.9.7-1.8 1.4-2.8 2.3-5-6.5-9.9-13-14.8-19.4.1-.1.1-.2.2-.3 4.9 1.7 9.8 3.5 15 5.3-.4-5.5-.7-10.6-1.1-15.8a4.33 4.33 0 0 0 .5-.1z"
            />
            <path
              className="flicker1"
              d="m428.7 302.9c-1.7 5.5-3.4 11.1-5.1 16.6l.3.3c3.6-2.6 7.2-5.2 10.9-7.9.8 1 1.4 1.9 2.2 2.9-6.7 4.9-13.2 9.7-19.8 14.5 1.2-5.7 3.2-11.2 4.5-17.1-3.5 2.6-7.1 5.2-10.7 7.8-.7-1-1.4-1.9-2.2-2.9 6.6-4.9 13.2-9.7 19.7-14.5 0 .2.1.2.2.3z"
            />
            <path
              className="flicker1"
              d="m323.5 544.8c.3 2.6.5 5.1.7 7.7-1.2.2-2.2.3-3.5.5-1-8-1.8-15.9-2.7-24 1.3-.1 2.4-.3 3.6-.4.5 4.1.9 8.2 1.4 12.5 2.7-.3 5.4-.6 8.3-.9-.4-4.2-.9-8.2-1.3-12.5 1.2-.1 2.4-.3 3.7-.4.9 8 1.7 15.8 2.6 23.9-1.3.1-2.3.3-3.6.4-.3-2.6-.6-5.1-.9-7.7-2.8.3-5.3.6-8.3.9z"
            />
            <path
              className="flicker1"
              d="m413.2 286.4c-.9 5.7-1.7 11.4-2.6 17.2.1.1.3.1.4.2 3.1-3.1 6.2-6.1 9.5-9.3l2.6 2.6c-5.9 5.8-11.6 11.5-17.3 17.2-.1-.1-.3-.1-.4-.2.8-5.6 1.7-11.3 2.5-16.9-.1-.1-.2-.1-.4-.2-1 1-2 1.9-3 2.9s-2.2 2.1-3.2 3.2-2 2.1-3 3.2a13.5 13.5 0 0 1 -1.1-.9c-.6-.5-1.2-.9-1.6-1.3 6-6.1 11.6-11.9 17.3-17.7a.19.19 0 0 1 .3 0z"
            />
            <path
              className="flicker1"
              d="m311.3 553.6h-3.8c-1.1-3.4-2.3-6.7-3.6-10.4-1.1 1.9-2.4 3.4-2.9 5.1s-.3 3.4-.4 5.2h-3.3v-23.7a12.13 12.13 0 0 1 1.8-.1h1.8v10.7c.1 0 .2.1.3.1a7.63 7.63 0 0 0 .8-1.1c1.7-2.7 3.3-5.5 4.9-8.3a2.18 2.18 0 0 1 2.5-1.4 20.68 20.68 0 0 0 2.5 0 12.2 12.2 0 0 1 -.9 1.7c-1.2 2-2.3 4-3.6 6a3.29 3.29 0 0 0 -.4 3.3c1.3 3.7 2.5 7.4 3.7 11.1a7.59 7.59 0 0 1 .6 1.8z"
            />
            <path
              className="flicker1"
              d="m425.1 335.8c-1.4.1-2.4.2-3.6.2-.6-1.1-1.1-2.1-1.7-3.4 8.9-1 17.6-2 26.8-3-6.1 6.9-11.9 13.4-17.9 20.2-.7-1.3-1.2-2.3-1.8-3.4.7-.9 1.5-1.8 2.3-2.8-1.3-2.6-2.7-5.2-4.1-7.8zm12-1c-.1-.1-.1-.2-.2-.4q-3.75.45-7.8.9a39.54 39.54 0 0 0 2.8 5.4c1.9-2.1 3.5-4 5.2-5.9z"
            />
            <path
              className="flicker1"
              d="m208.7 317.1c-.9 1.2-1.5 2.1-2.2 3.1-1.1-.3-2.2-.5-3.5-.8-1.7 2.4-3.4 4.8-5.2 7.2.7 1.1 1.2 2 1.9 3.1-.7 1-1.4 1.9-2.2 3.2-4.9-7.5-9.7-14.8-14.5-22.1.1-.1.1-.2.2-.3 8.3 2.1 16.7 4.3 25.5 6.6zm-17.2-.7a43.8 43.8 0 0 0 4.2 6.9c1.3-1.8 2.3-3.3 3.5-4.9-2.8-.7-5.3-1.4-7.7-2z"
            />
            <path
              className="flicker1"
              d="m333.4 248.9c1.8 8.5 3.7 17 5.5 25.7-.8-.1-1.4-.1-2-.2s-1.1-.2-1.8-.3c-.2-.8-.4-1.6-.6-2.5a1.18 1.18 0 0 0 -1.1-1.1c-2.6-.4-5.2-.8-7.9-1.2-.6 1-1.3 2-1.9 3-1.2-.2-2.4-.4-3.8-.6 4.5-7.8 8.8-15.3 13.2-22.9.2 0 .3 0 .4.1zm-1.5 10.1c-.1 0-.3-.1-.4-.1-1.3 2.2-2.6 4.4-4 6.9 2.1.3 4 .6 6 .9-.6-2.7-1.1-5.2-1.6-7.7z"
            />
            <path
              className="flicker1"
              d="m204.3 287.1a42 42 0 0 1 4.9-3.7c5.2-2.8 12 1.3 12.4 7.3a7.74 7.74 0 0 1 -2.4 6.6c-.2.2-.3.4-.5.5a1.24 1.24 0 0 0 -.2.3c1.3 1.5 2.6 3.2 4.1 4.9-1 .8-1.8 1.5-2.8 2.3-5.2-5.9-10.2-12-15.5-18.2zm11.7 8.2c2.7-2.3 2.9-5.4.6-7.7-2.1-2.1-5.1-2.1-6.9.1 2 2.5 4.1 5 6.3 7.6z"
            />
            <path
              className="flicker1"
              d="m289.1 548c-.2 1.2-.4 2.2-.6 3.2s-1 1-1.8 1c-5 0-8.9-1.9-11.4-6.3a11.3 11.3 0 0 1 .1-12 11.6 11.6 0 0 1 10.6-5.9 10.46 10.46 0 0 1 6.5 2c-.2 1.1-.4 2.4-.7 3.9-.7-.4-1.2-.8-1.8-1.1a8.46 8.46 0 0 0 -10 1.4 8.32 8.32 0 0 0 -1.3 9.9 8.47 8.47 0 0 0 8.8 4.2 15.69 15.69 0 0 1 1.6-.3z"
            />
            <path
              className="flicker1"
              d="m252 278.3c.6 1.2 1.2 2.3 1.8 3.4-3 4.2-9.3 5.7-14.3 3.5a11.94 11.94 0 0 1 -6.9-13.6c1.1-5.1 6-9.4 10.8-9.4.4.8.9 1.7 1.4 2.6.1.2.1.4.3.8-4.8.6-8.2 2.7-8.7 7.8a8.06 8.06 0 0 0 5.1 8.4c3.5 1.5 6.8.5 10.5-3.5z"
            />
            <path
              className="flicker1"
              d="m270.9 271.3c.5 1.3.9 2.5 1.3 3.6-3 3.3-9.7 4.5-14.7 1.3a11.82 11.82 0 0 1 -5-13.6 12.2 12.2 0 0 1 12.3-8.6c.4 1.1.8 2.3 1.3 3.6-5.9.4-8.9 2.4-9.9 6.4a8.58 8.58 0 0 0 3.5 9.1c3.1 2 6.9 1.5 11.2-1.8z"
            />
            <path
              className="flicker1"
              d="m226.2 506.5a22.5 22.5 0 0 0 -1.1-2.2 3.84 3.84 0 0 0 -3.8-2.3 2.63 2.63 0 0 0 -2.6 3.5 21.85 21.85 0 0 0 .9 2.3c.3.8.7 1.6 1 2.4a6.61 6.61 0 0 1 -6.1 9 8.41 8.41 0 0 1 -8.1-5.6 1.88 1.88 0 0 1 .3-1.9c.7-.7 1.3-1.5 2.1-2.4a10.49 10.49 0 0 0 .7 2.3 16.46 16.46 0 0 0 2 3.1 3.21 3.21 0 0 0 4.3.2 3.07 3.07 0 0 0 1.2-3.8c-.3-.9-.7-1.8-1-2.6-.3-1-.8-2-1-3a5.78 5.78 0 0 1 6-7.1 7.43 7.43 0 0 1 6.9 4.1 1.68 1.68 0 0 1 -.2 2.2 17.67 17.67 0 0 1 -1.5 1.8z"
            />
            <path
              className="flicker1"
              d="m229.8 532c-1.1-.7-2-1.2-3.1-1.9 3.5-5.8 6.9-11.5 10.4-17.2-1.7-1.1-3.3-2-5.1-3.2.6-1 1.2-2 2-3.2 4.4 2.7 8.8 5.3 13.4 8.1-.7 1.1-1.3 2.1-2 3.3-1.7-1-3.4-2-5.1-3.1-3.5 5.8-7 11.4-10.5 17.2z"
            />
            <path
              className="flicker1"
              d="m365.1 516.6c.9-.5 1.9-1.1 3-1.7 3.2 5.9 6.5 11.8 9.7 17.8 2.4-1.3 4.8-2.6 7.3-3.9.6 1.1 1.1 2 1.7 3.2-3.5 1.9-6.9 3.8-10.2 5.6-.9-1-10.6-18.6-11.5-21z"
            />
            <path
              className="flicker1"
              d="m304.3 247.1c1.4.1 2.4.1 3.7.2-.2 6.7-.4 13.3-.6 20.2l8.2.3v3.6c-4-.1-7.8-.3-11.9-.4.2-7.9.4-15.8.6-23.9z"
            />
            <path
              className="flicker1"
              d="m168.9 335.2c.6-1.2 1.1-2.1 1.7-3.3 6 3.1 11.9 6.2 18 9.4 1.2-2.2 2.3-4.4 3.6-6.6 1.1.6 2.1 1.1 3.2 1.7-1.7 3.3-3.4 6.5-5.2 9.9-7.1-3.6-14.1-7.3-21.3-11.1z"
            />
            <path
              className="flicker1"
              d="m235.9 293.1a32.12 32.12 0 0 0 -3 2.1c-4.6-6.7-9-13.2-13.6-19.9 1-.7 1.9-1.3 3-2.1 4.5 6.6 9 13.1 13.6 19.9z"
            />
            <path
              className="flicker1"
              d="m163.6 399.8a6.55 6.55 0 0 1 -13.1 0 6.47 6.47 0 0 1 6.6-6.3 6.38 6.38 0 0 1 6.5 6.3zm-11 .1a4.31 4.31 0 0 0 4.4 4.4 4.45 4.45 0 0 0 .1-8.9 4.36 4.36 0 0 0 -4.5 4.5z"
            />
            <path
              className="flicker1"
              d="m137.6 406.2c-1.7-2.2-3.3-4.5-5-6.7-.1 0-.3.1-.4.1v6.7h-1.7c-.4-.5-.5-9.7-.1-12.7a28.6 28.6 0 0 1 2.8 3.5 22.13 22.13 0 0 0 2.9 3.3v-6.5c.4-.1.7-.2 1-.3a3.44 3.44 0 0 1 .9.1v12.5c-.2-.1-.3 0-.4 0z"
            />
            <path
              className="flicker1"
              d="m146.3 400.3v-6.6a4.4 4.4 0 0 1 1-.2 2.49 2.49 0 0 1 .8.1v13c-2-2.6-3.7-4.9-5.7-7.5-.3 2.6 0 4.8-.3 7.2-.6 0-1.1.1-1.7.1v-13.1c2.1 2.4 3.6 4.9 5.9 7z"
            />
            <path
              className="flicker1"
              d="m469.5 399.8a7.93 7.93 0 0 1 1.1-4.5c1.5-2.4 4.2-2.5 5.6 0a8.94 8.94 0 0 1 0 9.2c-1.4 2.5-4.2 2.4-5.7-.1a9.12 9.12 0 0 1 -1-4.6zm1.7.1a21.24 21.24 0 0 0 1 3.6 1.32 1.32 0 0 0 2.5 0 8.39 8.39 0 0 0 .1-7.1 1.35 1.35 0 0 0 -2.6 0 20.14 20.14 0 0 0 -1 3.5z"
            />
            <path
              className="flicker1"
              d="m486.5 406.2c1-1.5 2-3 3.1-4.5-.8-.1-1.3-.1-1.9-.2a4 4 0 0 1 -3.3-4.7 4.18 4.18 0 0 1 8.2 1.5 9.78 9.78 0 0 1 -.9 2.9c-.7 1.4-1.6 2.7-2.3 4.1-.5.9-1.4 1.4-2.9.9zm2.1-6.6a2.18 2.18 0 0 0 2.3-2.2 2.26 2.26 0 0 0 -4.5 0 2.1 2.1 0 0 0 2.2 2.2z"
            />
            <path
              className="flicker1"
              d="m125.3 404.3h-2.9c-.2.6-.5 1.2-.7 1.9-.5 0-1 .1-1.5.1-.1-.2-.2-.3-.2-.4l3-11.4c.1-.4.6-.7.9-1 .3.4.7.7.8 1.1 1 3.7 2 7.4 3 11v.6h-1.7c-.2-.6-.4-1.1-.7-1.9zm-.5-2.1c-.3-1.2-.6-2.3-.8-3.4h-.3c-.3 1.1-.5 2.2-.8 3.4z"
            />
            <path
              className="flicker1"
              d="m462.8 404.5h4.2v1.8h-6.7a2.16 2.16 0 0 1 .6-2.7 26 26 0 0 0 3-3.6 5.6 5.6 0 0 0 1-2.4c.3-1.6-.8-2.4-2.3-1.9-.5.2-1 .5-1.6.8l-.6-1.5a4.71 4.71 0 0 1 3-1.6 2.83 2.83 0 0 1 3.4 2.7 6.32 6.32 0 0 1 -1.2 4.6 32.87 32.87 0 0 1 -2.8 3.8z"
            />
            <path
              className="flicker1"
              d="m480.1 395.7c-1.1-.2-1.6-.8-1.1-2.1h2.9v12.6h-1.8z"
            />
            <path
              className="flicker1"
              d="m311.4 341.9c1.8 1.8 3.6 3.4 5.4 5.2l15.3 15.3a10 10 0 0 1 1 1.1 2 2 0 0 1 -.3 2.4 1.84 1.84 0 0 1 -2.4.2 6.28 6.28 0 0 1 -1.2-1.1c-6.2-6.2-12.4-12.4-18.6-18.7-.5-.5-1-1.2-1.6-1.9-.7.7-1.2 1.1-1.7 1.6l-18.9 18.9-1.2 1.2a2 2 0 0 1 -2.6-.3 1.94 1.94 0 0 1 -.2-2.4 13.07 13.07 0 0 1 1.1-1.2l18.8-18.8a10.92 10.92 0 0 1 1.7-1.3 9.39 9.39 0 0 0 -1.2-1.4c-2.1-2.1-4.3-4.2-6.4-6.4a6.31 6.31 0 0 0 -7.1-1.6c-1.1.4-2.1.9-3.1 1.3-2 .8-3.7.7-5.3-1-2.7-2.8-5.4-5.4-8.1-8.1a6.54 6.54 0 0 1 0-9.6c2.6-2.7 5.2-5.3 7.9-7.8a6.5 6.5 0 0 1 8.3-.4 5.24 5.24 0 0 1 1.1 1c2.6 2.6 5.2 5.1 7.7 7.7 1.9 2 2.2 3.7.9 6.1a10.67 10.67 0 0 0 -1.5 5.6 5.22 5.22 0 0 0 1.4 3.4l7.8 7.8a.75.75 0 0 0 .5-.1c2.6-2.6 5.3-5.2 7.9-7.8 1.5-1.6 1.6-3.6 1.1-5.7a12.54 12.54 0 0 0 -1.2-3c-1.4-2.7-1.2-4.3.9-6.4 2.6-2.6 5.2-5.1 7.7-7.7a6.37 6.37 0 0 1 7.2-1.7 8.78 8.78 0 0 1 2.1 1.2c2.7 2.6 5.3 5.2 7.9 7.8 2.9 3 2.8 6.8-.1 9.7l-8.1 8.1a4.3 4.3 0 0 1 -5 1 25.57 25.57 0 0 1 -2.4-1.1 6.94 6.94 0 0 0 -8.4 1.6c-1.8 2-3.8 3.8-5.7 5.7a17.72 17.72 0 0 1 -1.4 1.6z"
            />
            <path
              className="flicker1, flicker2"
              d="m239.2 365.1c10.8.2 21.8 9.8 24.6 20.9 2.9 11.8-2.8 26.5-17.5 32.3v15.6h-7.1zm7.3 9.4v35.6c5.6-1.8 10.9-10 11.2-17.3s-4.9-15.7-11.2-18.3z"
            />
            <path
              className="flicker1"
              d="m365.9 379.4h5.2c3.2 18.2 6.4 36.3 9.7 54.5h-5.7c-.7-3.7-1.5-7.3-2.2-11.2h-8.8c-.7 3.7-1.5 7.4-2.3 11.1h-5.7c.6-3.7 1.2-7.3 1.8-11-1.6.1-2.3-.8-2.9-2-.5-.9-1.1-1.8-1.8-3h5.7c2.4-12.9 4.7-25.6 7-38.4zm6.2 38.3c-1.2-7.3-2.4-14.4-3.6-21.5h-.5c-1 7.1-1.9 14.3-2.9 21.5z"
            />
            <path className="flicker1" d="m236.6 433.9h-6.6v-69.1h6.6z" />
            <path
              className="flicker1"
              d="m345.9 428.5c.7 1.9 1.4 3.6 2 5.4h-19.5c4.5-18.3 9-36.3 13.5-54.5h5.7c-4.1 16.4-8.1 32.6-12.2 49.1z"
            />
            <path
              className="flicker1"
              d="m314.9 379.3c-4.1 16.5-8.1 32.7-12.2 49.2h10.3c.7 1.8 1.4 3.5 2 5.2-1.5.5-17.3.5-19.4.1q6.75-27.15 13.5-54.4c.4 0 .8-.1 1.1-.1z"
            />
            <path
              className="flicker1"
              d="m290.2 379.4h16.3c.1 1.3-12.4 51.9-13.5 54.6h-5.5c2-8.3 4-16.4 6.1-24.6s4.1-16.3 6.1-24.7h-7.4c-.6-1.7-1.3-3.4-2.1-5.3z"
            />
            <path
              className="flicker1"
              d="m326.1 434.1h-4.6a6.53 6.53 0 0 1 -1.1-.2c4.1-16.4 8.1-32.7 12.2-49.2h-7.4c-.7-1.8-1.4-3.4-2.1-5.3h16.7c-4.6 18.3-9.1 36.4-13.7 54.7z"
            />
            <path
              className="flicker1"
              d="m388.2 433.9a6.88 6.88 0 0 1 -1.3.2h-4.5c-3.2-18.2-6.3-36.4-9.5-54.6h5.7q4.8 27.15 9.6 54.4z"
            />
            <path
              className="flicker1"
              d="m276.9 379.3h4.6c.2 0 .5.1.8.1v54.6h-5.4z"
            />
            <path
              className="flicker1"
              d="m269.7 379.4h5c.4 1.4.5 52.6.1 54.6h-5.1z"
            />
            <path
              className="flicker1"
              d="m349.5 472.1c-1.6.8-3.1 1.5-5 2.5.2-1.7.2-3.1.5-4.4a3 3 0 0 0 -.8-2.7c-.9-.9-1.7-2-2.8-3.2 1.5-.3 2.7-.7 4-.9a2.63 2.63 0 0 0 2.2-1.7c.6-1.2 1.3-2.4 2-3.9.8 1.5 1.5 2.8 2.1 4.1a2.44 2.44 0 0 0 2 1.5c1.3.2 2.6.5 4.1.8-1 1.1-1.7 2.1-2.6 3a3.14 3.14 0 0 0 -1 3.2c.3 1.3.3 2.6.5 4.2-1.9-1-3.5-1.7-5.2-2.5z"
            />
            <path
              className="flicker1"
              d="m313.7 464.2c-1.1 1.3-2 2.4-3 3.5a2.48 2.48 0 0 0 -.7 2.2c.2 1.5.3 2.9.5 4.7-1.5-.7-2.7-1.3-3.9-1.9a2.13 2.13 0 0 0 -2.3 0c-1.2.7-2.4 1.2-3.9 2 .2-1.8.3-3.2.5-4.7a2.48 2.48 0 0 0 -.7-2.2c-1-1.1-1.9-2.2-3-3.5 1.5-.3 2.8-.7 4-.9a2.51 2.51 0 0 0 2.1-1.6c.5-1.3 1.2-2.4 2-4 .8 1.5 1.5 2.7 2.1 4a2.51 2.51 0 0 0 2.1 1.6 23.69 23.69 0 0 1 4.2.8z"
            />
            <path
              className="flicker1"
              d="m268 474.5a40.17 40.17 0 0 1 -3.6-1.8 2.55 2.55 0 0 0 -3 0 40.17 40.17 0 0 1 -3.6 1.8c.2-1.7.2-3.1.5-4.5a2.78 2.78 0 0 0 -.9-2.7c-.9-.9-1.7-2-2.8-3.2a32.49 32.49 0 0 1 3.8-.8 2.69 2.69 0 0 0 2.4-1.8c.5-1.2 1.2-2.3 2-3.8a35.59 35.59 0 0 1 2 3.8 2.69 2.69 0 0 0 2.4 1.8c1.2.2 2.4.5 3.9.8-1.1 1.2-1.9 2.3-2.9 3.3a2.6 2.6 0 0 0 -.8 2.5c.3 1.5.4 2.9.6 4.6z"
            />
          </NonnaLogo>
        </svg>
      </Logo>
      <span
        className="background-image"
        role="img"
        aria-label="La Piccola Nonna logga"
      ></span>
      <BoltImg left={"5%"} scale={"scale(1,1)"} seconds={"5s"} />
      <BoltImg right={"5%"} scale={"scale(-1,1)"} />
    </HeroWrapper>
  );
};

export default Hero;
